import { axiosOnce as axios } from '@/axios_once'

export default {
  createSession(username, password) {
    return axios.post('/session', { username, password })
  },
  deleteSession() {
    return axios.delete('/api/session')
  },
  createUser(url, params) {
    return axios.post(url, params)
  },
  createClient(clientParams) {
    return axios.post('/api/clients', { client: clientParams })
  },
  getCouponInfo(couponCode) {
    return axios.get(`/api/coupon_codes/${couponCode}`)
  },
  getCurrentClient() {
    return axios.get('/api/login')
  },
  getClient(id) {
    return axios.get(`/api/clients/${id}`)
  },
  getClientPhones(id) {
    return axios.get(`/api/clients/${id}/phones`)
  },
  getFrom(url) {
    return axios.get(url)
  },
  deleteAt(url) {
    return axios.delete(url)
  },
  deleteUserAt(url, reason) {
    if (reason) {
      return axios.delete(url, { params: { reason: reason } })
    }
    return axios.delete(url)
  },
  clientSignUp(signupParams) {
    return axios.post('/api/clients', { client: signupParams })
  },
  updateClient(id, clientParams) {
    return axios.patch(`/api/clients/${id}`, { client: clientParams })
  },
  updateClientPhone(clientId, phoneId, params) {
    return axios.patch(`/api/clients/${clientId}/phones/${phoneId}`, params)
  },
  createClientPhone(clientId, params) {
    return axios.post(`/api/clients/${clientId}/phones`, params)
  },
  deleteClientPhone(clientId, phoneId) {
    return axios.delete(`/api/clients/${clientId}/phones/${phoneId}`)
  },
  resetURLs(id) {
    return axios.get(`/api/clients/${id}/reset_urls`)
  },
  sendURLs(id) {
    return axios.get(`/api/clients/${id}/send_urls`)
  },
  sendMeetupUrlToClient(reservationId) {
    return axios.put(`/api/reservations/${reservationId}/send_meetup_url_to_client`)
  },
  updateClientProfile(id, profile) {
    return axios.patch(`/api/clients/${id}`, { profile })
  },
  getProperties() {
    return axios.get('/api/properties')
  },
  getInvitations() {
    return axios.get('/api/invitations')
  },
  sendInvitation(params) {
    return axios.post(`/api/invitations`, { invitation: params })
  },
  getClientProperties(client_id) {
    return axios.get('/api/properties?client_id=' + client_id)
  },
  getPropertyNotes(property_id) {
    return axios.get(`/api/properties/${property_id}/notes`)
  },
  getPropertyNotesForVisit(property_id) {
    return axios.get(`/api/properties/${property_id}/notes?for_visit=1`)
  },
  createPropertyNote(property_id, params) {
    return axios.post(`/api/properties/${property_id}/notes`, { private_note: params })
  },
  savePropertyNote(property_id, note_id, params) {
    return axios.patch(`/api/properties/${property_id}/notes/${note_id}`, { private_note: params })
  },
  deletePropertyNote(property_id, note_id) {
    return axios.delete(`/api/properties/${property_id}/notes/${note_id}`)
  },
  getNote(note_id) {
    return axios.get(`/api/notes/${note_id}`)
  },
  createNote() {
    return axios.post('/api/notes', { bedrooms_body: '' })
  },
  saveNote(id, attrs) {
    return axios.patch(`/api/notes/${id}`, attrs)
  },
  deleteVisitNote(reservationId) {
    return axios.delete(`/api/reservations/${reservationId}/notes`)
  },
  deleteVisitNotesForProperty(locationId) {
    return axios.delete(`/api/properties/${locationId}/notes`)
  },
  checkProperty(address, address2, city, state, zip_code, bedrooms, bathrooms, square_feet, context) {
    return axios.post('/api/properties/check', { address, address2, city, state, zip_code, bedrooms, bathrooms, square_feet, context })
  },
  createProperty(property) {
    return axios.post('/api/properties', property)
  },
  createPropertyAsAdmin(property) {
    property.context = 'admin_new_property'
    return axios.post('/api/properties', property)
  },
  updateProperty(id, params) {
    return axios.patch(`/api/properties/${id}`, {
      property: params
    })
  },
  getProperty(property_id, context) {
    if (context) {
      return axios.get(`/api/properties/${property_id}?context=${context}`)
    }
    return axios.get(`/api/properties/${property_id}`)
  },
  deleteProperty(location_id) {
    return axios.delete(`/api/properties/${location_id}`)
  },
  getAccessibleZips() {
    return axios.get('/api/zips')
  },
  getExtras(propertyId) {
    return axios.get(`/api/extras?property_id=${propertyId}`)
  },
  getCustomExtras(propertyId) {
    if (propertyId) {
      return axios.get(`/api/extras/custom?property_id=${propertyId}`)
    }
    return axios.get(`/api/extras/custom`)
  },
  sendPasswordResetEmail() {
    return axios.get('/api/reset_password')
  },
  getTerms() {
    return axios.get(`/api/documents/terms/`)
  },
  acceptTerms() {
    return axios.put('/terms')
  },
  getReservation(reservationId) {
    return axios.get(`/api/reservations/${reservationId}`)
  },
  getReservationAssignment(reservationId) {
    return axios.get(`/api/reservations/${reservationId}/assignment`)
  },
  reservationChangesRequested(reservationId, changes) {
    return axios.post(`/api/reservations/${reservationId}/changes_requested`, { changes: changes })
  },
  sendETA(reservationId) {
    return axios.put(`/api/reservations/${reservationId}/send_eta`)
  },
  requestETA(reservationId) {
    return axios.put(`/api/reservations/${reservationId}/request_eta`)
  },
  updateReservation(reservationId, params) {
    return axios.patch(`/api/reservations/${reservationId}`, { reservation: params })
  },
  updateReservationsFromThis(reservationId, params) {
    return axios.patch(`/api/reservations/${reservationId}/from`, { reservation: params })
  },
  getQuote(quoteId) {
    return axios.get(`/api/quotes/${quoteId}`)
  },
  getNotificationsForProperty(propertyId) {
    if (propertyId) {
      return axios.get(`/api/changes?property_id=${propertyId}`)
    }
    return axios.get(`/api/changes`)
  },
  validatePhone(phone) {
    return axios.post('/api/phones?validate=1', { phone: phone })
  },
  sendNotification(notification, params) {
    return axios.put('/api/send_notification/' + notification, params)
  },
  book(reservation) {
    return axios.post('/api/reservations', reservation)
  },
  requestBooking(reservation) {
    return axios.post('/api/reservations/validate', reservation)
  },
  recordCleaning(params) {
    return axios.post('/api/reservations/record_cleaning', params)
  },
  recordHWE(params) {
    return axios.post('/api/reservations/record_hwe', params)
  },
  forceLockRecord(record, id) {
    return axios.put(`/api/locking/${record}/${id}`, { force: 1 })
  },
  lockRecord(record, id) {
    return axios.put(`/api/locking/${record}/${id}`)
  },
  unlockRecord(record, id) {
    return axios.delete(`/api/locking/${record}/${id}`)
  },
  getNotificationsStatus() {
    return axios.get(`/api/notifications`)
  },
  disableNotifications() {
    return axios.put(`/api/notifications/disable`)
  },
  enableNotifications() {
    return axios.put(`/api/notifications/enable`)
  },
  getCommunications(params) {
    return axios.get("/api/communications?page=" + params.page + "&size=" + params.size + "&sent=" + params.sent + '&pending=' + params.pending + '&subject=' + params.subject + '&client=' + params.client + '&email=' + params.email + '&text=' + params.text)
  },
  createCleaningJob(params) {
    return axios.post('/api/cleaning_jobs', params)
  },
  getRoster(reservation_id) {
    return axios.get(`/api/roster?reservation_id=${reservation_id}`)
  },
  getRosterForProperty(property_id, for_hwe) {
    if (for_hwe) {
      return axios.get(`/api/hwe_roster?property_id=${property_id}`)
    }
    return axios.get(`/api/roster?property_id=${property_id}`)
  },
  sendTextMessage(params) {
    return axios.post('/api/text_messages', params)
  },
  getDaySheets(date) {
    return axios.get(`/api/days_sheets?date=${date}`)
  },
  getInvoices() {
    return axios.get('/api/client_invoices')
  },
  getRecentPayments() {
    return axios.get('/api/recent_payments')
  },
  payInvoice(invoiceId) {
    return axios.put(`/api/client_invoices/${invoiceId}/pay`)
  },
  getInvoice(invoiceId) {
    return axios.get(`/api/client_invoices/${invoiceId}`)
  },
  getCleanerInvoice(invoiceId) {
    return axios.get(`/api/cleaner_invoices/${invoiceId}`)
  },
  getTipInvoices() {
    return axios.get('/api/tip_invoices')
  },
  getTipInvoice(invoiceId) {
    return axios.get(`/api/tip_invoices/${invoiceId}`)
  },
  disburseTip(params) {
    return axios.post('/api/tip_invoices/disburse', params)
  },
  addAdjustmentToCleanerInvoice(invoiceId, params) {
    return axios.post(`/api/cleaner_invoices/${invoiceId}/adjustments`, params)
  },
  getCleanersForTipInvoices(tipInvoiceId) {
    return axios.get(`/api/tip_invoices/${tipInvoiceId}/cleaners`)
  },
  createIntent(invoiceId) {
    return axios.post('/api/stripe/intents')
  },
  confirmPayment(secret) {
    return axios.post('/api/stripe/confirm-payment', { secret: secret })
  },
  getJobForVisit(reservationId) {
    return axios.get(`/api/reservations/${reservationId}/job`)
  },
  getLastCleaningJob() {
    return axios.get('/api/cleaning_jobs/last')
  },
  getMainCleaningJob(id) {
    return axios.get(`/api/cleaning_jobs/${id}/main`)
  },
  updateCleaningJob(cleaning_job_id, params) {
    return axios.put(`/api/cleaning_jobs/${cleaning_job_id}`, params)
  },
  rateCleaningJobs(params) {
    return axios.post('/api/cleaning_jobs/rate', params)
  },
  getTipsForVisit(reservationId) {
    return axios.get(`/api/tips?reservation_id=${reservationId}`)
  },
  addTip(params) {
    return axios.post(`/api/tips`, params)
  },
  createSetupIntent(clientId, areaKey) {
    return axios.post('/api/stripe/setup_intents', { client_id: clientId, area_key: areaKey })
  },
  ensureStripeCustomer(clientId, areaKey) {
    return axios.post('/api/stripe/customers', { client_id: clientId, area_key: areaKey })
  },
  getClientPaymentInfo(clientId) {
    return axios.get(`/api/clients/${clientId}/payment_info`)
  },
  getClientPaymentInfoForProperty(clientId, propertyId) {
    return axios.get(`/api/clients/${clientId}/payment_info?location_id=${propertyId}`)
  },
  getCardsByArea(clientId) {
    return axios.get(`/api/clients/${clientId}/cards_by_area`)
  },
  getCardsForArea(clientId, areaKey) {
    return axios.get(`/api/clients/${clientId}/cards?area_key=${areaKey}`)
  },
  moveCard(cardId, dir) {
    return axios.put(`/api/cards/${cardId}/${dir}`)
  },
  deleteCard(cardId) {
    return axios.delete(`/api/cards/${cardId}`)
  },
  testChargeCard(cardId) {
    return axios.put(`/api/cards/${cardId}/test`)
  },
  testEnsureStripeCustomer(customerId) {
    return axios.post('/api/stripe/test_customers', { customer_id: customerId })
  },
  import_ics(propertyId, url, check_in_hour, check_out_hour) {
    return axios.post(`/api/properties/${propertyId}/import_ics`, { url: url, check_in_hour: check_in_hour, check_out_hour: check_out_hour })
  },
  mass_book(propertyId, starts) {
    return axios.post(`/api/properties/${propertyId}/mass_book`, { starts: starts })
  },
  getPropertyIcalConfig(propertyId) {
    return axios.get(`/api/properties/${propertyId}/ical`)
  },
  startDispute(dispute) {
    return axios.post('/api/disputes', dispute)
  },
  getDispute(disputeId) {
    return axios.get(`/api/disputes/${disputeId}`)
  },
  openDispute(disputeId) {
    return axios.put(`/api/disputes/${disputeId}/open`)
  },
  closeDispute(disputeId, action, params) {
    return axios.post(`/api/disputes/${disputeId}/${action}`, params)
  },
  getDisputeForVisit(visitId) {
    return axios.get(`/api/reservations/${visitId}/dispute`)
  },
  getJobImagesForVisit(visitId) {
    return axios.get(`/api/job_images?visit_id=${visitId}`)
  },
  approveJobImage(jobImageId) {
    return axios.put(`/api/job_images/${jobImageId}/approve`)
  },
  rejectJobImage(jobImageId, reason) {
    return axios.post(`/api/job_images/${jobImageId}/reject`, { reason: reason })
  },
  getHweServices() {
    return axios.get('/api/hwe_services')
  },
  getHweService(serviceId) {
    return axios.get(`/api/hwe_services/${serviceId}`)
  },
  getCreditTransactionsFromStripe(areaKey, customerId) {
    return axios.get(`/api/credit_transactions?area_key=${areaKey}&customer_id=${customerId}`)
  },
  refundCredits(areaKey, clientId, confirm) {
    return axios.post(`/api/credit_transactions/refund`, { area_key: areaKey, client_id: clientId, confirm: confirm })
  },
};
